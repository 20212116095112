import React from "react"
import Layout from "../components/layout"
import Start from "../components/test/start"
import { Helmet } from "react-helmet"

const StartPage = () => {
  return (
    <Layout type="hr" noHeaderOptions>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Evaluation</title>
      </Helmet>
      <Start />
    </Layout>
  )
}

export default StartPage
