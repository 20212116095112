import React, { useState } from "react"
import Modal from "../layout/modal"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import Button from "../base/buttons/regular"
import { Link } from "gatsby-plugin-intl"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"
import { useSelector } from "react-redux"
import { levelEvaluationTokenSelector } from "../../store/levelEvaluationToken/selectors"
import { levelEvaluationByTokenSelector } from "src/store/levelEvaluationByToken/selectors"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  startPageTitle: "The test is about to start",
  startPageVerticalTitle: "Start",
  startPageBody: `Make sure you have everything you need`,
  startPageBodyLastSentence: `focus and… GO!`,
  startButton: "Start",
  quickStartPageTitle: "The Protectas test is about to start",
  quickStartPageVerticalTitle: "Start",
  quickStartPageBody: "Make sure you are comfortable and focused. Just reply instinctively and relax.",
  quickStartPageCallToAction: "Start",
})
const Start = () => {
  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const { result: token } = useSelector(levelEvaluationTokenSelector)
  const { result: evaluation } = useSelector(levelEvaluationByTokenSelector(token))
  const isQuick = evaluation && evaluation?.letypecode === EvaluationType.EASY

  const currentSize = useWindowSize()
  const formatMessage = useFormatMessage()

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }

  if (isQuick) {
    return (
      <Modal
        title={formatMessage(messages.quickStartPageTitle)}
        mdWidth={widthOfModal}
        navigation={true}
        mobile={!navigationVisible}
        lgWidth="w-2/5"
        sideTitle={formatMessage(messages.quickStartPageVerticalTitle)}
        previous={`/audio${window?.location?.search}`}
      >
        <div className="flex flex-col items-center">
          <div className="max-w-md text-center">{formatMessage(messages.quickStartPageBody)}</div>
          <Link to={`/level-evaluation?token=${token}`}>
            <div className="flex justify-center mt-5">
              <Button primary className="text-center text-sm">
                {formatMessage(messages.quickStartPageCallToAction)}
              </Button>
            </div>
          </Link>
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      title={formatMessage(messages.startPageTitle)}
      mdWidth={widthOfModal}
      navigation={true}
      mobile={!navigationVisible}
      lgWidth="w-2/5"
      sideTitle={formatMessage(messages.startPageVerticalTitle)}
      previous={`/audio${window?.location?.search}`}
    >
      <div className="flex flex-col items-center">
        <div className="max-w-md text-center">{formatMessage(messages.startPageBody)}</div>
        <div className="mt-4 font-semibold">{formatMessage(messages.startPageBodyLastSentence)}</div>
        <Link to={`/level-evaluation?token=${token}`}>
          <div className="flex justify-center mt-5">
            <Button primary className="text-center text-sm">
              {formatMessage(messages.startButton)}
            </Button>
          </div>
        </Link>
      </div>
    </Modal>
  )
}

export default Start
